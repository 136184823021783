.app {
    // background-color: #252d4a;
    width: 60%;
    max-height: 1600px;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
button {
    font-size: 16px;
    border: none;
    color: #fff;
    background-color: #ff9900;
    border-radius: 5px;
    display: flex;
    padding: 5px;
    margin: 5px;
    justify-content: flex-start;
    align-items: center;
    // outline: 2px solid #234668;
    cursor: pointer;
}
button:hover {
    background-color: #3f3d56;
}

button:focus {
    outline: none;
}

button svg {
    margin-right: 5px;
}

    @media (max-width: 768px){
        width: 80%;
    }
}

.score-section {
    display: flex;
    font-size: 24px;
    align-items: center;
}

/* QUESTION/TIMER/LEFT SECTION */

.question-section {
    width: 100%;
    position: relative;
}

.question-count {
    margin-bottom: 20px;

    span {
        font-size: 28px;
    }
}

.question-text {
    margin-bottom: 12px;
}

.timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
}

/* ANSWERS/RIGHT SECTION */

.answer-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.correct {
    background-color: #2f922f;
}

.incorrect {
    background-color: #ff3333;
}
