@import '../utilities/variables';

.header-container {
    z-index: 20;
    width: 100%;
    display: flex;
    position: sticky;
    top: 0;
    background: #fff;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 1200px) {
        padding: 5px;
        justify-content: space-between;


    }

    .logotipo-principal {
        max-width: 250px;
        padding: 5px 0;
    }

    .header-container__logoandFlags {
        width: 100%;
        display: none;
        flex-wrap: wrap;
        justify-content: space-evenly;

        @media (min-width: 1200px) {
            display: flex;
            width: 30%;
        }
    }

    .mobileNav {
        @media (min-width: 768px) {
            display: none;
        }
    }

    .header-container__navigator {
        width: 100%;
        display: none;

        @media (min-width: 768px) {
            min-width: 30%;
            display: inline;
            // margin: 16px;
        }

        .navigator__unordened {
            display: flex;
            font-size: 1.1em;
            flex-wrap: wrap;
            text-align: center;
            align-items: center;
            list-style: none;
            justify-content: space-evenly;
            padding: 0;
            margin: 0;

            .unordened__element {
                text-decoration: none;
            }

            .unordened__element--button {
                background-color: #ff9900;
                padding: 5px;
                border-radius: 5px;
                a {
                    color: #fff;

                    &:hover {
                        color: #fff;
                    }
                }

                &:hover {
                    background-color: $color-oscuro;
                }
            }

            a {
                color: #ff9900;
                font-family: $title-font2;
                font-weight: bold;

                @media (min-width: 1024px) {
                    font-size: 1.2em;
                }

                &:hover {
                    color: $color-oscuro;
                }

            }

            @media (max-width: 768px) {
                margin: 1em;
            }

        }

    }
}



#nav-icon1 {
    display: none;
    width: 60px;
    height: 45px;
    position: relative;
    right: 2em;
    z-index: 10;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    @media (max-width: 756px) {
        display: block;
    }
}

#nav-icon1 span {
    display: block;
    position: absolute;
    height: 6px;
    width: 100%;
    background: $color-principal;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
    top: 0px;
}

#nav-icon1 span:nth-child(2) {
    top: 18px;
}

#nav-icon1 span:nth-child(3) {
    top: 36px;
}

#nav-icon1.open span:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

#nav-icon1.open span:nth-child(3) {
    top: 18px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}


.hiddenElement {
    padding: 2em;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;

    li {
        padding: 1em;
        font-family: $title-font2;
        font-weight: bold;

        a {
            color: $color-principal;

            &:hover {
                color: $color-oscuro;
            }
        }
    }
}

//active or inactive items
.activeList {
    border-bottom: 2px solid #ff9900;
}