.card-question{
    width: 70%;
    margin: 0 auto;
    min-height: 120px;
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.616);
    border-radius: 30px;
    padding:1em;
    button{
        border: none;
        margin-bottom: 5px;
        width: 100%;
        min-height: 2.3em;
        color: #fff;
        background: $color-principal;
        &:hover{
            background: $color-oscuro;
        }
    }
}


.top-answer{
    border-radius: 15px 15px 0 0;
}


.bottom-answer{
    border-radius: 0 0 15px 15px;

}