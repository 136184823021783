.carouselContainer {
  background-image: url("../img/font.jpg");
  background-size: cover;
  margin-top: 4em;
  display: flex;
  // flex-direction:
  align-items: center;
  justify-content: center;
  // padding: 1em;
  margin-bottom: 4em;
  width: 100%;
  height: 15em;
  color: white;
  text-align: center;
  @media (min-width: 768px) {
    width: 80%;
    height: 20em;
  }

  .carousel {
    width: 100%;
    height: 100%;
    .carousel__item {
      height: 15em;
      @media (min-width: 768px) {
        height: 20em;
      }
      .itemContainer {
        background: rgba(0, 0, 0, 0.349);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h3 {
          font-family: $title-font2;
          // color: $color-oscuro;
        }
        p {
          width: 70%;
          font-weight: lighter;
          @media (min-width: 768px) {
            font-size: 1.4em;
            width: 20em;
          }
        }
      }
    }
  }
}


