.lang_selector {
    display: flex;
    width: 100%;
    justify-content: end;
    margin-top: 90px;
    padding-right: 50px;
    margin-bottom: 10px;
    align-items: center;
    label {
        font-weight: bold;
        margin-right: 5px;
    }

    select {
        padding: 5px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
}