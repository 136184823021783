.mainFont{
    width: 100%;
    font-family: $main-font;
    font-size: 3rem;
    text-align: center;
    @media (max-width: 411px){
        font-size: 2.2em;
    }
}

.titleFont{
    font-size: 2em;
    font-weight: bold;
    @media (min-width: 768px) {
        font-size: 3.3em;
        
    }
}