.mainClass {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;

  padding: 0;
  margin: 0;

  .mainClass__header {
    width: 100%;
    background-image: url("../img/franciaHeaderMid.jpg");
    background-size: cover;
    object-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: 1080px) {
      flex-direction: row;
    }

    .header_text {
      padding: 6em 0;
      background: #3f3d567e;

      display: flex;
      flex-direction: column;
      color: #fff;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      height: 100%;

      h1 {
        width: 30%;
        margin-bottom: 2em;

        @media (max-width: 768px) {
          width: 70%;
          font-size: 2em;
        }
      }

      .header_text__icons {
        width: 100%;
        height: 30%;

        // background: #000;
        ul {
          height: 100%;

          // border: 2px solid red;
          li {
            width: 20%;
            text-align: center;
            font-size: 2em;
            // border: 2px solid blue;
            color: #fff;

            h3 {
              text-align: center;
              font-weight: bold;
              font-family: $main-font;
            }

            p {
              font-size: 0.6em;
            }

            @media (max-width: 768px) {
              width: 90%;
            }
          }

          @media (max-width: 768px) {
            flex-direction: column;
            padding-bottom: 2em;
            justify-content: start;
          }
        }
      }
    }

    @media (max-width: 768px) {
      justify-content: space-evenly;
    }
  }

  .courseInfo-container {
    display: flex;
    justify-content: space-evenly;
   
  }
}

.mainClass__cardI{
  padding: 5px;
  margin: 0 10px 40px 10px;
  max-width: 650px;
}




