.form-container {
    margin: 0 auto;
    padding: 1em;
    border-radius: 20px;
    width: 30vw;
    background-color: rgba(255, 153, 0, 0.781);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.425); // background: rgb(255, 153, 0);
    box-shadow: 1px 1px 7px black;
    position: relative;
    z-index: 10;
    .inputLabel-container {
        .inputIcon-container {
            display: flex;
            position: relative;
            .form-input {
                width: 100%;
                color: rgb(0, 0, 0);
                background: #f7f4f4;
                outline: none;
                padding: 5px 1em;
                border: none;
                // border-bottom: 1px solid #fff;
                &:hover {
                    outline: 1px solid rgb(22, 92, 223);
                }
                &:focus {
                    outline: 1px solid rgb(22, 92, 223);
                }
            }
            .form-icon {
                // position: absolute;
                cursor: pointer;
                color: rgb(190, 190, 190);
                background: #464646;
                width: 10%;
                height: 100%;
                padding: .7em;
                @media (max-width: 768px){
                    width: 15%;
                }
            }
        }
    }

    .error-message {
        // padding: 0.5px;
        background:  #0066B3;
        color: #fff;
        width: 94%;
        text-align: center;
    }

    @media (max-width: 980px) {
        width: 60vw;
    }
    @media (max-width: 568px) {
        width: 80vw;
        padding: 1em;
    }
}

.floating {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60%;
    display: none;
}

.LoginContainer{
    background-image: url('../img/login.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.filter{
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(13, 89, 230, 0.226);
}

.form-input:hover .form-icon{
    outline: 1px solid green;
}

.form-alert{
    position: absolute;
    right: 0;
    bottom: 0;
}