.floating-media {
    background: #25d366;
    height: 50px;
    width: 50px;
    position: fixed;
    padding-bottom: 0.7em;
    right: 18px;
    bottom: 82px;
    div {
        margin: 0 auto;
        a {
            color: #fff;
            font-size: 2em;
        }
    }
}

.floating-media2 {
    // background: linear-gradient(rgb(255, 143, 178) 0%, rgb(167, 151, 255) 50%, rgb(0, 229, 255) 100%);
    background: #00B2FF;
    height: 50px;
    width: 50px;
    position: fixed;
    padding-bottom: 0.7em;
    right: 18px;
    bottom: 140px;
    div {
        margin: 0 auto;
        a {
            color: #fff;
            font-size: 2em;
        }
    }
}
