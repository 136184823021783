.FormGridB{
    // background-color: red;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
    "nombre numero"
    "email email"
    "country country"
    "nivel nivel";
    justify-content: center;
    align-items: center;
    gap: 4px;
    .nombre{
        grid-area: nombre;
        display: flex;
        flex-direction: column;
    }
    .numero{
        grid-area: numero;
        display: flex;
        flex-direction: column;

    }

    .email{
        display: flex;
        flex-direction: column;

        grid-area: email;
    }
    .country{
        display: flex;
        flex-direction: column;

        grid-area: country;
    }
    .nivel{
        grid-area: nivel;
        display: flex;
        flex-direction: column;
    }
}