
.principalito{
    margin-bottom: 10em;
    text-align: center;
}
.termino{
    margin-top: 2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Terminosh1{
    width: 100%;
}

.letrerote{
    width: 100%;
    font-size: 1em;
    text-align: justify;
    padding: 1em 12em;
}

@media (max-width:768px){
    .letrerote{
        padding: 1em 3em;
        text-align: justify;
    }
}
.termsh3{
    color: #ff9900;
    text-align: center;
    font-size: 2em;
}

.termsh4{
    color: #ff9900;
    text-align: center;
    margin-top: .4em;
    font-size: 2em;
}

b{
    color: #3f3d56;
}