.comentarioContainer {
    width: 100vw;
    .comentarioContainer__lista {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        .lista-item__iframe {
            width: 450px;
            height: 300px;
            @media (max-width: 758px) {
                width: 300px;
            }
        }
        @media (max-width: 760px) {
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }
}
