* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    position: relative;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    ul {
        list-style: none;
    }

    a {
        text-decoration: none;
    }
}