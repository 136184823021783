.img-container img{
    width: 50px;
    // height: 50px;
    padding: 0;
    margin: 0;
}

.col .svgImage{
    text-align: center;
    max-width: 20em;
}