.cardImageComponent {
    display: flex;
    justify-content: center;
    width: 100%;
    img {
        
        cursor: pointer;
        width:70%;
        position: relative;

        &:hover {
            width: 90%;
            animation-name: zoom;
            animation-duration: 1.5s;
            animation-fill-mode: forwards;

        }

        @media(min-width: 810px) {
            width: 50%;

            &:hover {
                animation-name: zoomLap;
                animation-duration: 2s;
                animation-fill-mode: forwards;

            }
        }

        border-radius: 20px;
    }
}

@keyframes zoom {
    from {
        width: 70%;
    }

    to {
        width: 90%
    }
}

@keyframes zoomLap {
    from {
        width: 50%;
    }

    to {
        width: 60%;
    }
}