.footer {
  // position: absolute;
  // bottom: 0;
  // right: 0;
  // left: 0;
  // top: 100%;
  height: 100%;
  text-align: start;

  a {
    text-decoration: none;
    color: #fff;

    // font-family: ;
  }

  li {
    list-style: none;
  }

  .footer__container {
    background: #3d3d56;
    background-size: cover;

    // text-align: center;
    .social-items {
      width: 50%;
      align-items: center;
      padding: 0;
      height: 100%;
      li{
        a{
          &:hover{
            color: $color-principal;
          }
        }
      }
      @media (max-width: 768px) {
        width: 100%;

      }
    }

    margin: 0;

    .logoImg {
      width: 6em;
    }

    .socialItem {
      font-size: 2em;
    }
    section{
      align-items: center;
      @media (max-width: 768px){
        flex-direction: column;
      }
    }

    .end-footer{
      align-items: flex-start;
      font-size: .8em;
    }
  }
}