.mainClass__body {
    width: 100%;
    padding: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .body__embed {
        border: 5px solid #ff9900;
        box-shadow: 1px 10px 15px black;
        width: 20em;
        margin-bottom: 2em;

        @media (min-width: 720px) {
            width: 40em;
            margin: 0;
        }
    }

    .body__title {
        display: flex;
        flex-direction: column;

        @media (min-width: 768px) {
            width: 30em;
            text-align: justify;
        }

        h2,
        .title__font {
            color: $color-principal;
            font-family: $title-font2;
        }

        p {
            color: $color-oscuro;
            font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
                sans-serif;
            font-size: 1.5em;
        }
    }

    @media (min-width: 1080px) {
        flex-direction: row;
    }
}

