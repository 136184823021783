.main {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 2em;

    .section .main__h1 {
        font-size: 4em;
        text-align: center;
        font-family: sans-serif;
        color: #3f3d56;
    }

    .section__container--img {
        display: flex;
        justify-self: center;
        justify-content: center;
        margin-top: 7em;
        .container__img {
            width: 25%;
            // width: clamp(20em, 80%, 100em);
            @media(max-width: 768px){
                width: 60%;
            }
        }
    }

    .section .section__container--buttons {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        top: 2em;
    }
}


a {
    text-decoration: none;
    color: #fff;
    padding: 10px;
    &:hover{
        color: #fff
    }
}

.main .section .section__container--buttons button {
    width: 9em;
    // padding: 2px 0;
    font-size: 1.5em;
    border-radius: 30px;
    text-align: center;
    border: none;
    margin: 10px 10px;
    background: #ff9900;
    color: #fff;
    cursor: pointer;
}

.section__container--buttons {
    .boton_up,
    .boton_down {
        display: flex;
        flex-direction: column;
    }
}

.main .section .section__container--buttons button:hover {
    background: #3f3d56
}
