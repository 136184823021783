.shadow-drop-2-tb {
    -webkit-animation: shadow-drop-2-tb 0.4s cubic-bezier(0.165, 0.840, 0.440, 1.000) 1s both;
    animation: shadow-drop-2-tb 0.4s cubic-bezier(0.165, 0.840, 0.440, 1.000) 1s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-8 17:52:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes shadow-drop-2-tb {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent;
        box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent
    }

    100% {
        -webkit-transform: translateZ(50px);
        transform: translateZ(50px);
        -webkit-box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, .35), 0 12px 20px -12px rgba(0, 0, 0, .35);
        box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, .35), 0 12px 20px -12px rgba(0, 0, 0, .35)
    }
}

@keyframes shadow-drop-2-tb {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent;
        box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent
    }

    100% {
        -webkit-transform: translateZ(50px);
        transform: translateZ(50px);
        -webkit-box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, .35), 0 12px 20px -12px rgba(0, 0, 0, .35);
        box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, .35), 0 12px 20px -12px rgba(0, 0, 0, .35)
    }
}

.vibrate-3 {
    -webkit-animation: vibrate-3 0.5s linear 2 both;
    animation: vibrate-3 0.5s linear 2 both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-8 17:55:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-3
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-3 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }

    10% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }

    20% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }

    30% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }

    40% {
        -webkit-transform: translate(2px, 2px);
        transform: translate(2px, 2px);
    }

    50% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }

    60% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }

    70% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }

    80% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }

    90% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }

    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}

@keyframes vibrate-3 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }

    10% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }

    20% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }

    30% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }

    40% {
        -webkit-transform: translate(2px, 2px);
        transform: translate(2px, 2px);
    }

    50% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }

    60% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }

    70% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }

    80% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }

    90% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }

    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}

.bounce-top {
    -webkit-animation: bounce-top .9s both;
    animation: bounce-top .9s both
}

/* ----------------------------------------------
 * Generated by Animista on 2022-6-5 23:2:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes bounce-top {
    0% {
        -webkit-transform: translateY(-45px);
        transform: translateY(-45px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 1
    }

    24% {
        opacity: 1
    }

    40% {
        -webkit-transform: translateY(-24px);
        transform: translateY(-24px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    65% {
        -webkit-transform: translateY(-12px);
        transform: translateY(-12px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    82% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    93% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-4px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    25%,
    55%,
    75%,
    87% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1
    }
}

@keyframes bounce-top {
    0% {
        -webkit-transform: translateY(-45px);
        transform: translateY(-45px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 1
    }

    24% {
        opacity: 1
    }

    40% {
        -webkit-transform: translateY(-24px);
        transform: translateY(-24px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    65% {
        -webkit-transform: translateY(-12px);
        transform: translateY(-12px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    82% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    93% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-4px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    25%,
    55%,
    75%,
    87% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1
    }
}