.button, .button-topics{
    border: none;
    min-width: 9em;
    margin: 0 auto;
    padding: 10px 10px;
    border-radius: 10px;
    font-size: 1.5em;

    margin: 10px auto;
    background: $color-principal;
    color: #fff;
    &:hover{
        background: $color-oscuro;
    }
}


.button-topics{
    width: 15em;
    height: 4em;
    @media(max-width: 460px){
        min-width: 8em;
        min-height: 3em;
    }
}


.button-material{
    border: none;
    width: 8.3em;
    padding: 10px;
    min-height: 4.5em;
    box-shadow: 1px 1px 4px #000;
    margin: 0 auto;
    padding: 10px 0;
    border-radius: 10px;
    font-size: 1.2em;
    background: $color-oscuro;
    margin: 10px auto;
    color: #fff;
    &:hover{
        background: $color-principal;
    }
}

.button_noBorder{
    border: none;
    background: transparent;
}