.a {
    background: $color-principal;
    border: none;
    border-radius: 100%;
    box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.5) inset;
    color: #fff;
    position: relative;
    width: 5em;
    font-size: 1.6em;
    height: 4.7em;
    margin-bottom: 0.3em;
    font-family: $main-font;

    &:hover {
        text-shadow: 1px 1px 2px black;
    }

    @media (max-width: 768px) {
        font-size: 1em;
    }
}

.fatherRelative{
    position: relative;
}
.signerBall {
    background: $color-principal;
    width: 1em;
    height: 1em;
    border-radius: 100%;
    margin-bottom: 1em;
}

.content-container {
    width: 100%;
    height: 100%;
    position: relative;

    .content {
        font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
            sans-serif;
        width: 40vw;
        height: auto;
        position: absolute;
        font-size: 1.2em;

        @media (max-width: 810px) {
            width: 80vw;
            margin-left: 5em;
            left: -4em;
        }

        @media (max-width: 468px) {
            left: -6em;
            font-size: 0.8em;
        }
    }

    .right-text {
        left: -17em;
        
        @media (max-width: 810px) {
            left: -23em;
            margin-left:2em;
        }

        @media (max-width: 468px) {
            left: -14em;
            margin-left: 0;
        }
    }
}