@import '../utilities/variables';
thead {
    background: $color-principal;
}

tbody{
    background: #fff;
}

td{
    position: relative;
}

.selected{
    background: green;
}

.font-small{
    font-size: 12px;
}

.spanSelected{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(224, 128, 1, 0.842);
    width: 100%;
    height: 100%;
    color: rgb(253, 253, 253);
    display: flex;
    justify-content: center;
    align-items: center;
}

.itemSelected{
    background: $color-principal;
}

