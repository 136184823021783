.input_withoutB, .input_withoutB-small{
    width: 60%;
    padding: 0;
    margin-bottom: 10px;
    border: none;
    font-size: 1.2em;
    border-bottom: 2px solid $color-principal;
    margin-right: 15px;
    &:focus{
        outline: none;
    }
}

.input_withoutB-small{
    width:30%;
}