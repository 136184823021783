.pointer {
    cursor: pointer
}

.neon {
    color: #fff;
    text-shadow: 0 0 10px rgba(255, 255, 255, 1), 0 0 20px rgba(255, 255, 255, 1),
        0 0 30px rgb(255, 255, 255), 0 0 40px #ff9900, 0 0 70px #ff9900,
        0 0 80px #ff9900, 0 0 100px #ff9900;
    -webkit-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
    -moz-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
    -o-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
    transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);

    &:hover {
        text-shadow: 0 0 10px rgba(255, 255, 255, 1),
            0 0 20px rgba(255, 255, 255, 1), 0 0 30px rgba(255, 255, 255, 1),
            0 0 40px #00ffff, 0 0 70px #00ffff, 0 0 80px #00ffff, 0 0 100px #00ffff;
    }
}

h2 {
    color: $color-principal;
    font-family: $title-font2;
}

.logotipo2{
    margin-top: -50px;
    width: 300px;
}

.textarea{
    border: none;
    &:focus{
        outline: none;
    }
    width: 22em;
}

button:disabled{
    color: #000;
}